import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { getWindow } from 'ssr-window';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private cs: NgxCookieService) {}

  get(key: string) {
    const res = this.cs.get(key);

    if (res.startsWith('{') || res.startsWith('[')) {
      return JSON.parse(res);
    }
    return res;
  }

  set(
    key: string,
    value: unknown,
    options: { expires?: Date; crossSubdomain?: boolean } = {}
  ) {
    const window = getWindow();
    const { expires, crossSubdomain } = options;
    const currentDomain = '.' + window.location.hostname;
    this.cs.set(
      key,
      typeof value !== 'string' ? JSON.stringify(value) : value,
      {
        path: '/',
        expires:
          expires ?? new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
        secure: true,
        sameSite: 'Strict',
        // domain: crossSubdomain ? currentDomain : undefined,
      }
    );
  }

  delete(key: string, options: { crossSubdomain?: boolean } = {}) {
    const window = getWindow();
    const { crossSubdomain } = options;
    const currentDomain = '.' + window.location.hostname;
    // this.cs.delete(key, undefined, crossSubdomain ? currentDomain : undefined);
    // this.cs.delete(key);
    this.cs.delete(key, '/');
  }
}
